'use client'

import { type PropsWithChildren, useState } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import OAuthProvider from 'lib/oauth/Provider'

function ClientProviders({ children }: Readonly<PropsWithChildren>) {
  const [queryClient] = useState(() => new QueryClient())

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <OAuthProvider>
        {children}
      </OAuthProvider>
    </QueryClientProvider>
  )
}

export default ClientProviders
